<template>
    <div class="carousel relative w-full h-96" @mouseenter="pauseAutoScroll" @mouseleave="resumeAutoScroll">
        <div class="inner flex transition-transform duration-500 ease-in-out"
            :style="{ transform: `translateX(-${position * 100}%)` }">
            <div v-for="item in items" :key="item.id" class="card flex-shrink-0 w-full">
                <div class="image-container">
                    <img :src="`/${item.image}`" :alt="'Image ' + item.id"
                        class="rounded-lg md:rounded-xl lg:rounded-2xl w-full" loading="lazy" />
                </div>
            </div>
        </div>
        <button
            class="carousel-control-prev absolute left-4 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white rounded-full p-2 hover:bg-gray-700"
            @click="prev" aria-label="Previous">
            <ChevronLeftIcon class="h-6 w-6" />
        </button>
        <button
            class="carousel-control-next absolute right-4 top-1/2 transform -translate-y-1/2 bg-gray-800 text-white rounded-full p-2 hover:bg-gray-700"
            @click="next" aria-label="Next">
            <ChevronRightIcon class="h-6 w-6" />
        </button>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/24/outline';

// Define the type for CarouselItem
type CarouselItem = {
    id: number;
    image: string;
};

// Define the props
const props = defineProps<{
    items: CarouselItem[];
}>();

const position = ref(0);
let interval: number;

const timeScrolling = 5000;

const startAutoScroll = () => {
    interval = window.setInterval(() => {
        next();
    }, timeScrolling);
};

const stopAutoScroll = () => {
    clearInterval(interval);
};

const next = () => {
    position.value = (position.value + 1) % props.items.length;
};

const prev = () => {
    position.value = (position.value - 1 + props.items.length) % props.items.length;
};

const pauseAutoScroll = () => {
    stopAutoScroll();
};

const resumeAutoScroll = () => {
    startAutoScroll();
};

onMounted(() => {
    startAutoScroll();
});

onBeforeUnmount(() => {
    stopAutoScroll();
});
</script>

<style>
.carousel {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
}

.inner {
    display: flex;
    transition: transform 0.3s ease;
    width: 100%;
    height: 100%;
}

.card {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
}

.image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 100%;
    overflow: hidden;
}

.image-container img {
    width: 100%;
    height: auto;
    max-width: none;
}

.carousel-control-prev,
.carousel-control-next {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    font-size: 1.5rem;
    cursor: pointer;
}
</style>