<template>
    <div class="m-2"><a :href="url" class="hover:text-olve03" target="_blank" rel="noopener noreferrer">
            <li class="bg-white shadow-md rounded-lg p-4">
                {{ props.name }}
            </li>
        </a>
    </div>
</template>

<script setup lang="ts">
interface LinkProps {
    name: string;
    url: string;
}


const props = defineProps<LinkProps>();
</script>
