<script setup lang="ts">
import { computed, inject, onMounted, ref } from 'vue'
import Carousel from '../components/Carousel.vue'
import { Composer } from 'vue-i18n';

// Inject the globally provided 't' function
const t = inject<Composer['t']>('t');
if (!t) {
    throw new Error('t is not provided')
}

const featuresItems = computed(() => [
    {
        img: '/calendar.svg',
        title: "O'Punch",
        details: t?.('home_feature.opunch'),
        href: "https://opunch.org"
    },
    {
        img: '/Helga.png',
        title: 'WebRes.helga-o.com',
        details: t?.('home_feature.helga'),
        href: 'https://helga-o.com/webres/index.php?year=0&country=BEL'
    },
    {
        img: '/livelox.svg',
        title: 'Livelox',
        details: t?.('home_feature.livelox'),
        href: 'https://www.livelox.com/?tab=allEvents&timePeriod=pastMonth&country=BEL&sorting=time'
    },
    {
        img: '/calendar.svg',
        title: 'OrienteeringOnline.net',
        details: t?.('home_feature.orienteeringonline'),
        href: 'https://www.orienteeringonline.net/Default.aspx'
    },
    {
        img: '/iof.svg',
        title: 'O-Map Wiki',
        details: t?.('home_feature.omapwiki'),
        href: 'https://omapwiki.orienteering.sport/'
    },
    {
        img: '/strava.svg',
        title: 'Strava',
        details: t?.('home_feature.strava'),
        href: 'https://www.strava.com/clubs/719696'
    }
])

const carouselItems = ref([
    { id: 1, image: 'background_1.jpg' },
    { id: 2, image: 'background_2.jpg' },
    { id: 3, image: 'background_3.jpg' },
    { id: 4, image: 'background_4.jpg' }
]);

const showAds = ref(false);
const delayInSeconds = 1.5;
const targetAdsDate = new Date('2025-05-10T23:59:59');

const isBeforeTargetDate = () => {

    const currentDate = new Date();
    return currentDate < targetAdsDate;
};

onMounted(() => {
    if (isBeforeTargetDate()) {

        setTimeout(() => {
            showAds.value = true;
        }, delayInSeconds * 1000);
    }
});

</script>

<template>
    <section class="p-4">
        <div class="container mx-auto">
            <div class="-mx-4 flex flex-wrap">
                <div class="w-full px-auto">
                    <div class="mx-auto mb-5 text-center lg:mb-20">
                        <span class="text-primary mb-2 block text-lg font-semibold"></span>
                        <h2 class="text-dark mb-3 text-3xl leading-[1.2] font-bold sm:text-4xl md:text-[40px]">{{
                            t?.('titleHome') }}</h2>

                        <div class="flex justify-center my-4 min-h-4">
                            <a href="https://www.opunch.org/event/3373" target="_blank">
                                <Transition name="roll">
                                    <img v-if="showAds" class="rounded-lg md:rounded-xl lg:rounded-2xl w-full"
                                        src="/biathlon-orientation.jpg" alt="Biathlion Orientation" />
                                </Transition>
                            </a>
                        </div>

                        <p class="text-body-color text-base">{{ t?.('descriptionHome') }}</p>
                    </div>
                </div>
            </div>

            <div class="flex-grow mb-4">
                <Carousel :items="carouselItems" />
            </div>

            <div class="-mx-4 flex flex-wrap">
                <template v-for="(item) in featuresItems" :key="index">
                    <div class="w-full px-4 md:w-1/2 lg:w-1/3">
                        <a :href="item.href" target="_blank">
                            <div
                                class="mb-9 rounded-[20px] bg-olve08 border-olve08 p-10 shadow-2 text-olve01 hover:shadow-lg hover:bg-olve05 hover:text-olve06 md:px-7 xl:px-10 ">
                                <div class="mb-2 flex items-center justify-center rounded-2xl">
                                    <img :src="item.img" alt="Image description" class="h-32 w-32" />
                                </div>
                                <h4 class="text-dark mb-[14px] text-2xl font-semibold"> {{ item.title }} </h4>
                                <p class="text-body-color"> {{ item.details }} </p>
                            </div>
                        </a>
                    </div>
                </template>
            </div>
        </div>
    </section>
</template>

<style>
.bounce-enter-active {
    animation: bounce-in .5s;
}

.bounce-leave-active {
    animation: bounce-in .5s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(1);
    }
}

.roll-enter-active {
    animation: roll-in .5s;
}

.roll-leave-active {
    animation: roll-in .5s reverse;
}

@keyframes roll-in {
    0% {
        transform: scale(0) rotateZ(0deg) translateX(-250px);
        opacity: 0;
    }

    25% {
        opacity: 1;
    }

    100% {
        transform: scale(1) rotateZ(360deg) translateX(0px);
        opacity: 1;
    }
}
</style>
