<template>
    <div class="container mx-auto p-4">
        <h2 class="my-4 text-4xl tracking-tight font-extrabold text-center">Liens</h2>
        <div v-for="category in categories" :key="category.name" class="mb-8">
            <h2 class="text-xl font-bold mb-4">{{ category.name }}</h2>
            <ul class="space-y-2">
                <LinkItem v-for="link in category.links" :key="link.url" :name="link.name" :url="link.url" />
            </ul>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import LinkItem from '../components/LinkItem.vue';
import linksData from '../data/links.json';

const categories = ref(linksData.categories);
</script>
