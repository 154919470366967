<script setup lang="ts">
import { inject } from 'vue';
import { Composer } from 'vue-i18n';

// Inject the globally provided 't' function
const t = inject<Composer['t']>('t');
if (!t) {
    throw new Error('t is not provided')
}
</script>
<template>
    <h2 class="my-4 text-4xl tracking-tight font-extrabold text-center">{{ t('co.title') }}</h2>
    <p class="m-4">{{ t('co.description') }}</p>
    <h2 class="text-xl font-bold mb-2">{{ t('co.howItWorks') }}</h2>
    <ol class="list-decimal pl-4 m-4">
        <li>{{ t('co.howItWorksDescription') }}</li>
        <li>{{ t('co.leisure') }}</li>
        <li>{{ t('co.competition') }}</li>
        <li>{{ t('co.equipment') }}</li>
    </ol>
    <h2 class="text-xl font-bold mb-2">{{ t('co.compass') }}</h2>
    <p class="m-4">{{ t('co.compassDescription') }}</p>
    <h2 class="text-xl font-bold mb-2">{{ t('co.marker') }}</h2>
    <p class="m-4">{{ t('co.markerDescription') }}</p>
    <h2 class="text-xl font-bold mb-2">{{ t('co.map') }}</h2>
    <p class="m-4">{{ t('co.mapDescription') }}</p>
    <h2 class="text-xl font-bold mb-2">{{ t('co.whyCO') }}</h2>
    <ul class="list-disc pl-6 m-4">
        <li>{{ t('co.learn') }}</li>
        <li>{{ t('co.familyTime') }}</li>
        <li>{{ t('co.challenge') }}</li>
        <li>{{ t('co.stayFit') }}</li>
    </ul>
    <p class="m-4">{{ t('co.additionalInfo') }}</p>
</template>
