<script setup lang="ts">
import { inject, ref } from 'vue';
import ImageGallery from '../components/ImageGallery.vue';
import { Composer } from 'vue-i18n';

// Inject the globally provided 't' function
const t = inject<Composer['t']>('t');
if (!t) {
    throw new Error('t is not provided')
}
const imageItems = ref([
    { id: 1, image: 'wagener/Wagener2011.jpg', year: '2011' },
    { id: 2, image: 'wagener/Wagener2012.jpg', year: '2012' },
    { id: 3, image: 'wagener/Wagener2016.jpg', year: '2016' },
    { id: 4, image: 'wagener/Wagener2018.jpg', year: '2018' },
    { id: 5, image: 'wagener/Wagener2019.jpg', year: '2019' },
    { id: 6, image: 'wagener/Wagener2020.jpg', year: '2020' },
    { id: 7, image: 'wagener/Wagener2021.jpg', year: '2021' },
    { id: 8, image: 'wagener/Wagener2022.jpg', year: '2022' },
    { id: 9, image: 'wagener/Wagener2023.jpg', year: '2023' },
    { id: 10, image: 'wagener/Wagener2024.jpg', year: '2024' }
]);
</script>
<template>
    <div class="container mx-auto p-4">
        <h2 class="my-4 text-4xl tracking-tight font-extrabold text-center">{{ t('wagener.title') }}</h2>
        <ImageGallery :items="imageItems" />
    </div>
</template>
