<template>
    <button v-if="isOutOfScreen" id="go-to-top" 
        class="hidden lg:block fixed bottom-16 right-8 bg-olve04 text-olve06 text-xl px-4 py-2 rounded-full shadow-md hover:text-olve08"
        @click="scrollToTop">
        Remonter
    </button>
</template>

<script setup lang="ts">
import { computed, reactive } from 'vue';

const state = reactive({
  isOutOfScreen: false,
});

const handleScroll = () => {
  state.isOutOfScreen = window.scrollY > 0;
};

window.addEventListener('scroll', handleScroll);

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

const isOutOfScreen = computed(() => state.isOutOfScreen);

</script>

<style scoped>
#go-to-top {
    transition: background-color 0.2s ease;
}
</style>