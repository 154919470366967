<template>
    <Disclosure as="nav" class="bg-gradient-to-r from-olve01 via-olve02 to-olve05 border-olve08" v-slot="{ open }">
        <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div class="relative flex h-24 flex-middle items-center justify-between">
                <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
                    <!-- Mobile menu button-->
                    <DisclosureButton
                        class="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-olve01 hover:text-olve08 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-olve04">
                        <span class="absolute -inset-0.5" />
                        <span class="sr-only">Open main menu</span>
                        <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
                        <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
                    </DisclosureButton>
                </div>
                <div class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start text-center">
                    <div class="flex flex-shrink-0 items-center">
                        <a href="/"><img class="h-10 w-auto" src="/LogoOlve.svg" alt="OLV-EIFEL Logo" /></a>
                    </div>
                    <div class="hidden sm:ml-6 sm:block">
                        <div class="flex space-x-4">
                            <RouterLink v-for="item in navigation" :key="item.name" :to="item.route"
                                class="text-olve06 hover:bg-olve01 hover:text-olve08 rounded-md px-3 py-2 text-sm font-medium"
                                exactActiveClass="bg-olve01 text-olve07 rounded-md px-3 py-2 text-sm font-medium">
                                {{ item.name }}
                            </RouterLink>
                        </div>
                    </div>
                </div>
                <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                    <a href="https://opunch.org" target="_blank">
                        <button type="button"
                            class="relative rounded-full bg-olve01 p-1 text-olve06 hover:text-olve08 flex items-center lg:p-3">
                            <span class="absolute -inset-1.5" />
                            <CalendarIcon class="h-6 w-6" aria-hidden="true" />
                            <span class="ml-2 hidden sm:inline">{{ t?.('nav.opunch') }}</span>
                        </button>
                    </a>
                </div>
            </div>
        </div>

        <DisclosurePanel class="sm:hidden">
            <div class="space-y-1 px-2 pb-3 pt-2">
                <RouterLink v-for="item in navigation" :key="item.name" :to="item.route"
                    class="text-olve06 block rounded-md px-3 py-2 text-base font-medium">
                    {{ item.name }}
                </RouterLink>
            </div>
        </DisclosurePanel>
    </Disclosure>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue';
import { Composer } from 'vue-i18n';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { Bars3Icon, CalendarIcon, XMarkIcon } from '@heroicons/vue/24/outline'

// Inject the globally provided 't' function
const t = inject<Composer['t']>('t');
if (!t) {
    throw new Error('t is not provided')
}

const navigation = computed(() => [
    { name: t('nav.home'), route: '/', current: true },
    { name: t('nav.co'), route: '/co', current: false },
    { name: t('nav.about'), route: '/about', current: false },
    { name: t('nav.register'), route: '/register', current: false },
    { name: t('nav.contact'), route: '/contact', current: false },
    { name: t('nav.wagener'), route: '/wagener', current: false }
])
</script>

<style>
.flex-middle {
    align-items: center;
}
</style>