<template>
    <footer
        class="relative z-10 bg-gradient-to-r from-olve01 via-olve02 to-olve05 border-olve08 text-olve06 pt-20 pb-10 lg:pt-[120px] lg:pb-20">
        <div class="mx-auto">
            <div class="flex flex-wrap">
                <div class="w-full px-4 sm:w-2/3 lg:w-3/12">
                    <div class="w-full mb-10">
                        <div class="flex justify-center mb-6">
                            <a href="/" class="mb-6 inline-block max-w-[160px]">
                                <img src="/LogoOlve.svg" alt="OLV-EIFEL Logo" class="h-48 w-auto" />
                            </a>
                        </div>
                        <div class="flex justify-center mb-6">
                            <p class="flex items-center text-olve08 text-sm font-medium">
                                <span class="mr-3 text-primary">
                                    <AtSymbolIcon class="h-6 w-6" aria-hidden="true" />
                                </span>
                                <span class="hover:text-olve07">
                                    <a :href="`mailto:${t?.('general.contact_mail').replace('[at]', '@')}`">{{
                                        t?.('general.contact_mail').replace('[at]', '@') }}</a>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>

                <div v-for="menu in menus" :key="menu.title" class="w-full px-4 sm:w-1/2 lg:w-2/12">
                    <div class="w-full mb-10">
                        <h4 class="text-lg font-semibold text-olve08 mb-9">{{ menu.title }}</h4>
                        <ul class="space-y-3">
                            <li v-for="item in menu.items" :key="item.text">
                                <div v-if="item.rtrlink">
                                    <RouterLink :to="item.link" :target="item.target ? '_blank' : ''"
                                        class="inline-block text-base leading-loose text-body-color hover:text-olve08">
                                        {{ item.text }}
                                    </RouterLink>
                                </div>
                                <div v-else>
                                    <a :href="item.link" :target="item.target ? '_blank' : ''"
                                        class="inline-block text-base leading-loose text-body-color hover:text-olve08">
                                        {{ item.text }}
                                    </a>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="w-full px-4 sm:w-1/2 lg:w-3/12">
                    <div class="w-full mb-10">
                        <h4 class="text-lg font-semibold text-dark dark:text-white mb-9">{{ t?.('menu.socialnetwork') }}
                        </h4>
                        <div class="flex items-center mb-6">
                            <a href="https://www.facebook.com/olveifel" target="_blank"
                                class="flex items-center justify-center w-8 h-8 mr-3 rounded-full sm:mr-4 lg:mr-3 xl:mr-4">
                                <img src="/facebook.svg" alt="Facebook">
                            </a>
                            <a href="https://www.instagram.com/olveifel/" target="_blank"
                                class="flex items-center justify-center w-8 h-8 mr-3 rounded-full sm:mr-4 lg:mr-3 xl:mr-4">
                                <img src="/instagram.svg" alt="Instagram">
                            </a>
                            <a href="https://www.strava.com/clubs/719696" target="_blank"
                                class="flex items-center justify-center w-8 h-8 mr-3 rounded-full sm:mr-4 lg:mr-3 xl:mr-4">
                                <img src="/strava.svg" alt="Strava">
                            </a>
                        </div>
                        <div class="mb-6">
                            <p class="text-base text-body-color dark:text-dark-6">Orientierungs-Lauf-Vereinigung-Eifel
                            </p>
                            <p class="text-base text-body-color dark:text-dark-6">{{ t?.('menu.createddateclub') }} : 19
                                août 1983</p>
                            <p class="text-base text-body-color dark:text-dark-6">{{ t?.('menu.numberclub') }} :
                                0425.896.217</p>
                        </div>
                        <div class="mb-6">
                            <div class="flex items-center">
                                <button @click="setLanguage('en')">
                                    <img src="../assets/flags/flag-gb-svgrepo-com.svg" alt="English"
                                        class="h-8 w-8 mr-2" />
                                </button>
                                <button @click="setLanguage('fr')">
                                    <img src="../assets/flags/flag-fr-svgrepo-com.svg" alt="Français"
                                        class="h-8 w-8 mr-2" />
                                </button>
                                <button @click="setLanguage('de')">
                                    <img src="../assets/flags/flag-de-svgrepo-com.svg" alt="Deutsch"
                                        class="h-8 w-8 mr-2" />
                                </button>
                            </div>
                        </div>
                        <div class="mb-6">
                            <a href="https://portal.olv-eifel.be" target="_blank">{{ t?.('menu_footer.portal') }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue';
import { AtSymbolIcon } from '@heroicons/vue/24/outline';
import { Composer } from 'vue-i18n';

// Declare a type for the available languages
type Locale = 'en' | 'fr' | 'de';

const changeLanguage = inject?.('changeLanguage') as (language: Locale) => void;

const setLanguage = (language: Locale) => {
    if (changeLanguage) {
        changeLanguage(language);
    }
};

// Inject the globally provided 't' function
const t = inject<Composer['t']>('t'); // Inject the globally provided 't' function

if (!t) {
    throw new Error("The 't' function is not provided"); // Error handling for missing 't' function
}

const menus = computed(() => [
    {
        title: t?.('menu_footer.nav.title'),
        items: [
            { text: t?.('menu_footer.nav.co'), link: '/co', rtrlink: true, target: false },
            { text: t?.('menu_footer.nav.about'), link: '/about', rtrlink: true, target: false },
            { text: t?.('menu_footer.nav.register'), link: '/register', rtrlink: true, target: false },
            { text: t?.('menu_footer.nav.contact'), link: '/contact', rtrlink: true, target: false },
            { text: t?.('menu_footer.nav.links'), link: '/links', rtrlink: true, target: false }
        ]
    },
    {
        title: t?.('menu_footer.federations.title'),
        items: [
            { text: t?.('menu_footer.federations.iof'), link: 'https://orienteering.sport/', rtrlink: false, target: true },
            { text: t?.('menu_footer.federations.absobvos'), link: 'https://www.orienteering.be/', rtrlink: false, target: true },
            { text: t?.('menu_footer.federations.frso'), link: 'https://frso.be/', rtrlink: false, target: true },
            { text: t?.('menu_footer.federations.los'), link: 'https://www.los-ostbelgien.be/', rtrlink: false, target: true }
        ]
    },
    {
        title: t?.('menu_footer.ressources.title'),
        items: [
            { text: t?.('menu_footer.ressources.photos'), link: 'https://photos.olv-eifel.be', rtrlink: false, target: true },
            { text: t?.('menu_footer.ressources.doma'), link: 'https://doma.olv-eifel.be', rtrlink: false, target: true },
            { text: t?.('menu_footer.ressources.events'), link: 'https://www.opunch.org/events/?level3=16&level2=1&level1=8&fromdate=&level=&registration=ALL&cancelled=Y&show=list&start=0&orgview=&inquery=1', rtrlink: false, target: true },
        ]
    }
]);
</script>
