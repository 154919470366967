<template>
    <div class="bg-gray p-6">
        <div class="flex flex-wrap justify-start">
            <a v-for="item in props.items" :key="item.id" :href="'#' + item.year">
                <span class="bg-olve01 flex items-center justify-center w-24 h-10 rounded-full text-sm mb-2 mr-2">
                    <p class="text-olve06 hover:bg-olve01 mx-2 font-medium">{{ item.year }}</p>
                </span>
            </a>
        </div>
    </div>

    <hr />

    <div class="image-gallery">
        <div v-for="item in props.items" :key="item.id" class="mb-8">
            <h4 :id="item.year" class="text-dark text-center mb-[14px] text-2xl font-semibold"> {{ item.year }} </h4>
            <div class="flex flex-col">
                <img :src="`/${item.image}`" :alt="'Image ' + item.id" loading="lazy" />
            </div>
            <hr />
        </div>
    </div>
</template>

<script setup lang="ts">
type imageItems = {
    id: number;
    image: string;
    year: string;
};

const props = defineProps<{
    items: imageItems[];
}>();
</script>