<template>
    <h1 class="mb-4 text-4xl tracking-tight font-extrabold text-center">{{ t?.('register.title') }}</h1>
    <div class="rounded pb-3">
        <h2 class="text-2xl font-bold mb-4">{{ t?.('register.benefits.subtitle') }}</h2>
        <ul class="space-y-4 text-left">
            <li class="flex items-center space-x-3 rtl:space-x-reverse">
                <svg class="flex-shrink-0 w-3.5 h-3.5 text-green-500" aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M1 5.917 5.724 10.5 15 1.5" />
                </svg>
                <span>{{ t?.('register.benefits.frso') }}</span>
            </li>
            <li class="flex items-center space-x-3 rtl:space-x-reverse">
                <svg class="flex-shrink-0 w-3.5 h-3.5 text-green-500" aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M1 5.917 5.724 10.5 15 1.5" />
                </svg>
                <span>{{ t?.('register.benefits.insurrance') }}</span>
            </li>
            <li class="flex items-center space-x-3 rtl:space-x-reverse">
                <svg class="flex-shrink-0 w-3.5 h-3.5 text-green-500" aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M1 5.917 5.724 10.5 15 1.5" />
                </svg>
                <span>{{ t?.('register.benefits.newsletter') }}</span>
            </li>
            <li class="flex items-center space-x-3 rtl:space-x-reverse">
                <svg class="flex-shrink-0 w-3.5 h-3.5 text-green-500" aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M1 5.917 5.724 10.5 15 1.5" />
                </svg>
                <span>{{ t?.('register.benefits.training') }}</span>
            </li>
            <li class="flex items-center space-x-3 rtl:space-x-reverse">
                <svg class="flex-shrink-0 w-3.5 h-3.5 text-green-500" aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M1 5.917 5.724 10.5 15 1.5" />
                </svg>
                <span>{{ t?.('register.benefits.relay') }}</span>
            </li>
            <li class="flex items-center space-x-3 rtl:space-x-reverse">
                <svg class="flex-shrink-0 w-3.5 h-3.5 text-green-500" aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M1 5.917 5.724 10.5 15 1.5" />
                </svg>
                <span>{{ t?.('register.benefits.camp') }}</span>
            </li>
        </ul>
    </div>
    <div class="rounded pb-3">
        <h2 class="text-2xl font-bold mb-4">{{ t?.('register.licence.subtitle') }}</h2>
        <table class="w-full table-fixed">
            <thead>
                <tr class="bg-gradient-to-r from-olve01 via-olve02 to-olve05 border-olve08">
                    <th v-for="header in headers" :key="header.key"
                        class="px-4 py-2 text-left text-xs font-medium text-olve06 uppercase tracking-wider">
                        {{ header.text }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in items" :key="item.id" class="hover:bg-olve05">
                    <td v-for="header in headers" :key="header.key" class="px-4 py-2 text-sm">
                        {{ item[header.key as keyof typeof item] }}
                    </td>
                </tr>
            </tbody>
        </table>
        <p class="pt-4 italic">{{ t?.('register.licence.notice_young') }}</p>
    </div>

    <hr>

    <div class="rounded pb-3">
        <h2 class="text-2xl font-bold mb-4">{{ t?.('register.step.title') }}</h2>
        <div class="flex items-center justify-center">
            <div class="w-full max-w-4xl">
                <div class="grid gap-6 row-gap-10 lg:grid-cols-2">
                    <div class="lg:py-6 lg:pr-16">
                        <div v-for="step in steps" :key="step.key" class="flex">
                            <div class="flex flex-col items-center mr-4">
                                <div>
                                    <div class="flex items-center justify-center w-10 h-10 border rounded-full">
                                        <svg class="w-4 text-gray-600" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round" viewBox="0 0 24 24">
                                            <line fill="none" stroke-miterlimit="10" x1="12" y1="2" x2="12" y2="22">
                                            </line>
                                            <polyline fill="none" stroke-miterlimit="10" points="19,15 12,22 5,15">
                                            </polyline>
                                        </svg>
                                    </div>
                                </div>
                                <div class="w-px h-full bg-gray-300"></div>
                            </div>
                            <div class="pt-1 pb-8">
                                <p class="mb-2 text-lg font-bold text-olve01">{{ step.title }}</p>
                                <p class="text-gray-700" v-html="step.description"></p>
                            </div>
                        </div>
                        <div class="flex">
                            <div class="flex flex-col items-center mr-4">
                                <div>
                                    <div class="flex items-center justify-center w-10 h-10 border rounded-full">
                                        <svg class="w-6 text-gray-600" stroke="currentColor" viewBox="0 0 24 24">
                                            <polyline fill="none" stroke-width="2" stroke-linecap="round"
                                                stroke-linejoin="round" stroke-miterlimit="10" points="6,12 10,16 18,8">
                                            </polyline>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div class="pt-1">
                                <p class="mb-2 text-lg font-bold text-olve01">{{ t?.('register.step.step_final') }}</p>
                                <p class="text-gray-700"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <hr>

    <div class="rounded pb-3">
        <h2 class="text-2xl font-bold mb-4">{{ t?.('register.document.title') }}</h2>
        <ul class="space-y-2">
            <li v-for="document in documents" :key="document.name" class="flex items-center space-x-2">
                <template v-if="document.type === 'pdf'">
                    <DocumentIcon class="w-6 h-6 text-red-500" />
                </template>
                <template v-else-if="document.type === 'excel'">
                    <DocumentTextIcon class="w-6 h-6 text-green-500" />
                </template>
                <template v-else-if="document.type === 'word'">
                    <DocumentTextIcon class="w-6 h-6 text-blue-500" />
                </template>
                <a :href="document.link" :download="document.name" target="_blank"
                    class="text-blue-500 hover:text-blue-700 underline">
                    {{ document.name }}
                </a>
            </li>
        </ul>
    </div>
</template>

<script setup lang="ts">
import { inject, computed } from 'vue';
import { Composer } from 'vue-i18n';
import { DocumentTextIcon, DocumentIcon } from '@heroicons/vue/24/outline';

// Inject the globally provided 't' function
const t = inject<Composer['t']>('t');
if (!t) {
    throw new Error('t is not provided')
}

const headers = computed(() => [
    { key: 'licence', text: 'Type de licence' },
    { key: 'category', text: 'Catégorie' },
    { key: 'affiliation', text: 'Affiliation' },
    { key: 'certificat', text: 'Certificat' },
]);

const items = computed(() => [
    { id: 1, licence: t('register.licence.array_licence_competition'), category: t('register.licence.array_categorie_adult'), affiliation: t('register.licence.array_affiliation_adult'), certificat: t('register.licence.array_cm_yes') },
    { id: 2, licence: t('register.licence.array_licence_competition'), category: t('register.licence.array_categorie_young'), affiliation: t('register.licence.array_affiliation_young'), certificat: t('register.licence.array_cm_yes') },
    { id: 3, licence: t('register.licence.array_licence_hobbies'), category: t('register.licence.array_categorie_all'), affiliation: t('register.licence.array_affiliation_hobbies'), certificat: t('register.licence.array_cm_no') },
    { id: 4, licence: t('register.licence.array_licence_honor'), category: t('register.licence.array_categorie_all'), affiliation: t('register.licence.array_affiliation_honnor'), certificat: t('register.licence.array_cm_no') },
]);
const steps = computed(() => [
    { key: 1, title: t('register.step.step1_title'), description: t('register.step.step1_description') },
    { key: 2, title: t('register.step.step2_title'), description: t('register.step.step2_description') },
    { key: 3, title: t('register.step.step3_title'), description: t('register.step.step3_description') + t('general.contact_mail').replace('[at]', '@') },
    { key: 4, title: t('register.step.step4_title'), description: t('register.step.step4_description') + t('general.bank_account') },
]);

const documents = computed(() => [
    { name: t('register.document.affiliation'), link: '/public/doc_affiliation.xlsx', type: 'excel' },
    { name: t('register.document.affiliation'), link: '/public/doc_affiliation.pdf', type: 'pdf' },
    { name: t('register.document.cm'), link: '/public/doc_cm.docx', type: 'word' },
    { name: t('register.document.cm'), link: '/public/doc_cm.pdf', type: 'pdf' },
    { name: t('register.document.ah'), link: '/public/doc_ah.docx', type: 'word' },
    { name: t('register.document.ah'), link: '/public/doc_cm.pdf', type: 'pdf' },
]);

</script>
