<template>
    <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-center">OrientierungsLauf Vereinigung Eifel V.O.G 1972</h2>
    <div class="mx-auto grid max-w-7xl gap-x-8 gap-y-20 px-6 py-4 lg:px-8 xl:grid-cols-3">
        <div class="max-w-2xl">
            <h2 class="text-3xl font-bold tracking-tight sm:text-4xl">{{ t?.('contact.details') }}</h2>
            <div class="mt-6 text-lg leading-8 text-gray-600">
                <ul>
                    <li>
                        <div class="flex py-2">
                            <p class="flex items-center text-sm font-medium">
                                <span class="mr-3 text-olve01">
                                    <HomeIcon class="h-6 w-6" aria-hidden="true" />
                                </span>
                                <span class="hover:text-olve05">
                                    <a href="https://www.openstreetmap.org/#map=12/50.2915/6.0837" target="_blank">
                                        {{ t?.('general.headquarters') }}
                                    </a>
                                </span>
                            </p>
                        </div>
                    </li>
                    <li>
                        <div class="flex py-2">
                            <p class="flex items-center text-sm font-medium">
                                <span class="mr-3 text-olve01">
                                    <CreditCardIcon class="h-6 w-6" aria-hidden="true" />
                                </span>
                                <span class="px-2 py-1 flex items-center">
                                    {{ t?.('general.bank_account') }}                                    
                                </span>
                                <span class="mr-3 text-olve01 cursor-pointer" @click="copyToClipboard(t?.('general.bank_account'))">
                                    <ClipboardIcon class="h-6 w-6" aria-hidden="true" />
                                </span>
                            </p>
                        </div>
                    </li>
                    <li>
                        <div class="flex py-2">
                            <p class="flex items-center text-sm font-medium">
                                <span class="mr-3 text-olve01">
                                    <PaperAirplaneIcon class="h-6 w-6" aria-hidden="true" />
                                </span>
                                <span class="px-2 py-1 flex items-center hover:text-olve05">
                                    <a :href="`mailto:${t('general.contact_mail').replace('[at]', '@')}`">{{ t('general.contact_mail').replace('[at]', '@') }}</a>
                                </span>
                            </p>
                        </div>
                    </li>
                    <li>
                        <div class="flex py-2">
                            <p class="flex items-center text-sm font-medium">
                                <span class="mr-3 text-olve01">
                                    <CurrencyEuroIcon class="h-6 w-6" aria-hidden="true" />
                                </span>
                                <span class="px-2 py-1 flex items-center hover:text-olve05">
                                    <a :href="`mailto:${t('general.finance_mail').replace('[at]', '@')}`">{{ t('general.finance_mail').replace('[at]', '@') }}</a>
                                </span>
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <ul role="list" class="grid gap-x-8 gap-y-12 sm:grid-cols-2 sm:gap-y-16 xl:col-span-2">
            <li>
                <div class="flex items-center gap-x-6">
                    <img class="h-16 w-16 rounded-full"
                        src="/avatar.svg" alt="img">
                    <div>
                        <h3 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Thomas BRÉDO
                        </h3>
                        <p class="text-sm font-semibold leading-6 text-olve01">{{ t?.('contact.function.president') }}</p>
                        <div class="flex">
                            <p class="flex items-center text-sm font-medium">
                                <span class="mr-3">
                                    <EnvelopeIcon class="h-6 w-6" aria-hidden="true" />
                                </span>
                                <span class="hover:text-olve05">
                                    <a href="mailto:t.bredo@olv-eifel.be">t.bredo@olv-eifel.be</a>
                                </span>
                            </p>
                        </div>
                        <div class="flex my-1">
                            <p class="flex items-center text-sm font-medium">
                                <span class="mr-3">
                                    <DevicePhoneMobileIcon class="h-6 w-6" aria-hidden="true" />
                                </span>
                                <span class="hover:text-olve05">
                                    <a href="tel:+32472721088">+32 472 72 10 88</a>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </li>
            <li>
                <div class="flex items-center gap-x-6">
                    <img class="h-16 w-16 rounded-full"
                        src="/avatar.svg" alt="img">
                    <div>
                        <h3 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Magali Rauw
                        </h3>
                        <p class="text-sm font-semibold leading-6 text-olve01">{{ t?.('contact.function.vicepresident') }}</p>
                        <div class="flex">
                            <p class="flex items-center text-sm font-medium">
                                <span class="mr-3">
                                    <EnvelopeIcon class="h-6 w-6" aria-hidden="true" />
                                </span>
                                <span class="hover:text-olve05">
                                    <a href="mailto:m.rauw@olv-eifel.be">m.rauw@olv-eifel.be</a>
                                </span>
                                <span></span>
                            </p>
                        </div>
                    </div>
                </div>
            </li>
            <li>
                <div class="flex items-center gap-x-6">
                    <img class="h-16 w-16 rounded-full"
                        src="/avatar.svg" alt="img">
                    <div>
                        <h3 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Corentin Tonneau
                        </h3>
                        <p class="text-sm font-semibold leading-6 text-olve01">{{ t?.('contact.function.secretary') }}</p>
                        <div class="flex">
                            <p class="flex items-center text-sm font-medium">
                                <span class="mr-3">
                                    <EnvelopeIcon class="h-6 w-6" aria-hidden="true" />
                                </span>
                                <span class="hover:text-olve05">
                                    <a href="mailto:c.tonneau@olv-eifel.be">c.tonneau@olv-eifel.be</a>
                                </span>
                                <span></span>
                            </p>
                        </div>
                    </div>
                </div>
            </li>
            <li>
                <div class="flex items-center gap-x-6">
                    <img class="h-16 w-16 rounded-full"
                        src="/avatar.svg" alt="img">
                    <div>
                        <h3 class="text-base font-semibold leading-7 tracking-tight text-gray-900">Jérémy Brédo
                        </h3>
                        <p class="text-sm font-semibold leading-6 text-olve01">{{ t?.('contact.function.tresory') }}</p>
                        <div class="flex">
                            <p class="flex items-center text-sm font-medium">
                                <span class="mr-3">
                                    <EnvelopeIcon class="h-6 w-6" aria-hidden="true" />
                                </span>
                                <span class="hover:text-olve05">
                                    <a href="mailto:j.bredo@olv-eifel.be">j.bredo@olv-eifel.be</a>
                                </span>
                                <span></span>
                            </p>
                        </div>
                    </div>
                </div>
            </li>
            <li>
                <div class="flex items-center gap-x-6">
                    <img class="h-16 w-16 rounded-full"
                        src="/avatar.svg" alt="img">
                    <div>
                        <h3 class="text-base font-semibold leading-7 tracking-tight text-gray-900">François Tonneau
                        </h3>
                        <p class="text-sm font-semibold leading-6 text-olve01">{{ t?.('contact.function.member') }}</p>
                        <div class="flex">
                            <p class="flex items-center text-sm font-medium">
                                <span class="mr-3">
                                    <EnvelopeIcon class="h-6 w-6" aria-hidden="true" />
                                </span>
                                <span class="hover:text-olve05">
                                    <a href="mailto:f.tonneau@olv-eifel.be">f.tonneau@olv-eifel.be</a>
                                </span>
                                <span></span>
                            </p>
                        </div>
                    </div>
                </div>
            </li>
            <li>
                <div class="flex items-center gap-x-6">
                    <img class="h-16 w-16 rounded-full"
                        src="/avatar.svg" alt="img">
                    <div>
                        <h3 class="text-base font-semibold leading-7 tracking-tight text-gray-900">William Tonneau</h3>
                        <p class="text-sm font-semibold leading-6 text-olve01">{{ t?.('contact.function.member') }}</p>
                        <div class="flex">
                            <p class="flex items-center text-sm font-medium">
                                <span class="mr-3">
                                    <EnvelopeIcon class="h-6 w-6" aria-hidden="true" />
                                </span>
                                <span class="hover:text-olve05">
                                    <a href="mailto:w.tonneau@olv-eifel.be">w.tonneau@olv-eifel.be</a>
                                </span>
                                <span></span>
                            </p>
                        </div>
                        <div class="flex my-1">
                            <p class="flex items-center text-sm font-medium">
                                <span class="mr-3">
                                    <DevicePhoneMobileIcon class="h-6 w-6" aria-hidden="true" />
                                </span>
                                <span class="hover:text-olve05">
                                    <a href="tel:+32495209503">+32 495 20 95 03</a>
                                </span>
                                <span></span>
                            </p>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script setup lang="ts">
    import { inject } from 'vue';
    import { Composer } from 'vue-i18n';
    import { CreditCardIcon, CurrencyEuroIcon, EnvelopeIcon, HomeIcon, PaperAirplaneIcon, DevicePhoneMobileIcon, ClipboardIcon } from '@heroicons/vue/24/outline';


    // Inject the globally provided 't' function
    const t = inject<Composer['t']>('t');
    if (!t) {
        throw new Error('t is not provided')
    }

    function copyToClipboard(text: string) {
        navigator.clipboard.writeText(text)
        .then(() => {
            alert(t?.('general.copy_clipboard_ok'));
        })
        .catch(err => {
            console.error(t?.('general.copy_clipboard_ko'), err);
        });
    }
</script>