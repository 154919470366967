<script setup lang="ts">
import { inject } from 'vue';
import { Composer } from 'vue-i18n';

// Inject the globally provided 't' function
const t = inject<Composer['t']>('t');
if (!t) {
    throw new Error('t is not provided')
}
</script>
<template>
    <div class="container mx-auto p-4">
        <h2 class="my-4 text-4xl tracking-tight font-extrabold text-center">{{ t('history.title') }}</h2>
        <p class="m-4">{{ t('history.intro') }}</p>
        <p class="m-4">{{ t('history.sportEvolution') }}</p>
        <p class="m-4">{{ t('history.firstCompetition') }}</p>
        <p class="m-4">{{ t('history.popularity') }}</p>
        <p class="m-4">{{ t('history.postWarSpread') }}</p>
        <p class="m-4">{{ t('history.federationFormation') }}</p>
        <p class="m-4">{{ t('history.championships') }}</p>
        <p class="m-4">{{ t('history.scandinavianPopularity') }}</p>
        <p class="m-4">{{ t('history.terrainTypes') }}</p>
        <p class="m-4">{{ t('history.worldChampionships') }}</p>
        <p class="m-4">{{ t('history.disabledAthletes') }}</p>
        <p class="m-4">{{ t('history.indoorOrientation') }}</p>
        <p class="m-4">{{ t('history.summary') }}</p>
    </div>
</template>