<template>
  <section class="relative z-10 bg-primary py-[120px]">
    <div class="container mx-auto">
      <div class="-mx-4 flex">
        <div class="w-full px-4">
          <div class="mx-auto max-w-[400px] text-center">
            <h2 class="mb-2 text-[50px] font-bold leading-none text-dark sm:text-[80px] md:text-[100px]">
              404
            </h2>
            <h4 class="mb-3 text-[22px] font-semibold leading-tight text-olve01">
              {{ title }}
            </h4>
            <p class="mb-8 text-lg text-olve01">The page you are looking for it maybe deleted</p>
            <a href="/"
              class="inline-block rounded-lg text-xl border border-olve01 px-8 py-3 text-center text-base font-semibold text-olve01 transition hover:bg-olve05 hover:text-olve07">
              Retourner à l'accueil
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup lang="ts">
const title = "Oops! That page can’t be found";
</script>