import { createRouter, createWebHistory } from 'vue-router'

import Home from './views/Home.vue'
import NotFound from './views/NotFound.vue'
import About from './views/About.vue'
import Contact from './views/Contact.vue'
import Register from './views/Register.vue'
import CO from './views/CO.vue'
import Wagener from './views/Wagener.vue'
import LinksPage from './views/LinksPage.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/about',
        name: 'About',
        component: About,
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact,
    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
    },
    {
        path: '/co',
        name: 'CO',
        component: CO,
    },
    {
        path: '/links',
        name: 'LinksPage',
        component: LinksPage,
    },
    {
        path: '/wagener',
        name: 'Wagener',
        component: Wagener,
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound,
    }
]

const scrollBehavior = (_to: any, _from: any, savedPosition: any) => {
    if (savedPosition) {
        return savedPosition
    } else {
        return { top: 0 }
    }
}

export default createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior
});