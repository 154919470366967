import "./style.css";
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createI18n } from 'vue-i18n';
import './types/i18n.d.ts';
import messages from './locales';

// Déclare un type pour les langues disponibles
type Locale = 'en' | 'fr' | 'de';

// Obtenir la langue du navigateur
const browserLang = navigator.language.split('-')[0] as Locale || 'en';

const i18n = createI18n({
    locale: browserLang, // Définit la langue par défaut
    messages,
});

const app = createApp(App);
app.use(router);

// Fournir l'instance i18n globalement
app.use(i18n);
app.provide('t', i18n.global.t);
app.provide('locale', i18n.global.locale);

// Créer une fonction pour changer la langue
const changeLanguage = (language: Locale) => {
    i18n.global.locale = language;
};

// Fournir la fonction changeLanguage globalement
app.provide('changeLanguage', changeLanguage);

app.mount('#app');